var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-list-group",
    { attrs: { flush: "" } },
    [
      _c(
        "b-row",
        { staticClass: "py-2 mx-1 bg-secondary" },
        [
          _c("b-col", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("payments.tables.description")) + " "),
          ]),
          _c("b-col", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("payments.tables.value")) + " "),
          ]),
          _c("b-col", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("payments.tables.status")) + " "),
          ]),
          _c("b-col", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("payments.tables.overdue_limit")) + " "),
          ]),
        ],
        1
      ),
      _vm._l(_vm.transactionsChildren, function (item) {
        return _c(
          "b-list-group-item",
          {
            key: item.parcela,
            staticClass: "mx-1",
            class: item.status === "NESTED_NEGOTIATED" ? "negotiated" : "",
          },
          [
            _c(
              "b-row",
              [
                _c("b-col", [_vm._v(" " + _vm._s(item.description) + " ")]),
                _c("b-col", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.setPriceFormat(
                          _vm.$t("payments.labels.currency"),
                          item.amount_reference
                        )
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "b-col",
                  [
                    _c(
                      "big-badge",
                      { attrs: { variant: _vm.setStatusFormat(item.status) } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("transaction_status")(item.status)) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("b-col", [
                  _c("span", { staticClass: "pl-3" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(item.expired_at, "DD/MM/YYYY")
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }