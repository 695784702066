<template>
  <b-list-group flush>
    <b-row class="py-2 mx-1 bg-secondary">
      <b-col class="font-weight-bold">
        {{ $t('payments.tables.description')}}
      </b-col>
      <b-col class="font-weight-bold">
        {{ $t('payments.tables.value')}}
      </b-col>
      <b-col class="font-weight-bold">
        {{ $t('payments.tables.status')}}
      </b-col>
      <b-col class="font-weight-bold">
        {{ $t('payments.tables.overdue_limit')}}
      </b-col>
    </b-row>
    <b-list-group-item 
      v-for="item in transactionsChildren" 
      :key="item.parcela"
      class="mx-1"
      :class="item.status === 'NESTED_NEGOTIATED' ? 'negotiated' : '' "
    >
      <b-row>
        <b-col>
          {{ item.description }}
        </b-col>
        <b-col>
          {{ setPriceFormat($t('payments.labels.currency'), item.amount_reference)  }}
        </b-col>
        <b-col>
          <big-badge :variant="setStatusFormat(item.status)">
            {{ item.status | transaction_status }}
          </big-badge>
        </b-col>
        <b-col>
          <span class="pl-3">
            {{ item.expired_at | moment('DD/MM/YYYY') }}
          </span>
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { formatPrice } from '@utils/numbers';
import { formatStatus } from '@utils/wallet';
import BigBadge from '@components/shared/big-badge';
export default {
  props: {
    transactionsChildren: {
      type: Array,
      default: []
    }
  },
  components: {
    BigBadge
  },
  methods: {
    setPriceFormat(_currency, _value) {
      return formatPrice(_currency, _value);
    },
    setStatusFormat(status) {
      if (!status) return 'STATUS_ERROR';
      return formatStatus(status);
    },
  }
}
</script>

<style scoped>
.negotiated {
  background: rgba(219, 244, 213, 0.81);
}
</style>
